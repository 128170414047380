import styled from "styled-components";
import { Text, GridBackground } from "../ui";
import { Color } from "../util";

export const Container = styled.div`
  flex: 1;
  background-color: ${({ theme }) =>
    Color.makeHexTranslucent(theme.palette["yellow-400"], 1)};
  display: flex;
  overflow: hidden;
  /* ${GridBackground("yellow-500", 0.3)} */
  justify-content: center;
`;

export const Content = styled.div`
  margin: 60px auto;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 30px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin: 30px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;

  > :first-child {
    margin-bottom: -2px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-bottom: 20px;
    margin-right: auto;
  }
`;

export const Sticker = styled.div`
  position: relative;
`;

export const Sticker1 = styled.img`
  transform: rotate(5.44deg);
`;

export const Sticker2 = styled.img`
  transform: rotate(-1.71deg);
`;

export const MichelleHeader = styled(Text)`
  line-height: 32px;

  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
`;

export const ArtHeader = styled(Text)`
  line-height: 32px;

  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 40%);
`;

export const Philosophy = styled.div`
  max-width: 162px;

  > :not(:first-child) {
    margin-top: 24px;
  }

  > :last-child {
    display: flex;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    max-width: 100%;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const NavContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-left: -30px;
`;

export const Art = styled.div`
  width: 490px;
  z-index: 1;
  img {
    border: 1px solid ${({ theme }) => theme.palette["red-500"]};
    cursor: pointer;
  }

  > :not(:first-child) {
    margin-top: 14px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    width: 100%;
    margin: 20px;
  }
`;

export const FullRow = styled.div`
  width: 100%;
`;

export const SplitRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 14px;
`;

export const Seed = styled.img`
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 0;
  transform: translate(-10%, -50%) rotate(-20deg);
  opacity: 0.3;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 45px 60px;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) =>
    Color.makeHexTranslucent(theme.palette.maroon, 0.8)};
`;

export const SelectedImage = styled.img`
  height: 100%;
  object-fit: contain;
`;
