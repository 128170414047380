import React from "react";
import { Link } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";

import styled from "styled-components";
import { base } from "../ui";
import { Color } from "../util";

const Container = styled.div`
  position: fixed;
  bottom: 12px;
  left: 12px;
`;

export const Back = ({ color = "green-500" }) => {
  return (
    <Container>
      <Link to="/">
        <IoArrowBackCircleOutline
          size={48}
          color={Color.makeHexTranslucent(base.palette[color], 0.5)}
        />
      </Link>
    </Container>
  );
};
