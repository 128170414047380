import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import { Nav } from "../components";
import { Text, Link } from "../ui";
import * as Styled from "./home.styles.js";
import seed1 from "../seeds/home_1.png";
import seed2 from "../seeds/home_2.png";
import seed2Hover from "../seeds/home_2_hover.png";
import { useDimensions } from "../hooks/useDimensions";

export const Home = () => {
  const { isMobile } = useDimensions();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Styled.Container>
      <Styled.OuterContainer>
        <Styled.InnerContainer>
          <Styled.Content>
            <Text type="display-h1" color="green-500">
              Hi, I'm Michelle Fang!
            </Text>
            <br />
            <Text type="text-t1">
              I am based in the Bay, leading strategy for the CEO at{" "}
              <Link href="https://www.cerebras.net/">Cerebras</Link>. Before, I
              advised{" "}
              <Link href="https://www.blumenthal.senate.gov/">
                Senator Blumenthal
              </Link>{" "}
              on AI policy, digital platforms, and competition law.
              <br />
              <br />
              Straddling tech and policy over the past five years, I&apos;ve
              invested in growth-stage startups at{" "}
              <Link href="https://www.accel.com/">Accel</Link> and tried out
              fintech banking at{" "}
              <Link href="https://www.goldmansachs.com/">Goldman Sachs</Link>.
              While my curiosity for <i>what should be</i> led me towards
              government, my optimism for <i>what could be</i> drew me to
              building.
              <br />
              <br />
              At Yale, I was a Rhodes finalist and researched for{" "}
              <Link href="https://som.yale.edu/faculty-research/faculty-directory/fiona-m-scott-morton">
                Obama&apos;s Chief Antitrust Economist
              </Link>{" "}
              on digital platform monopolies. I care about building ethical
              technology and co-founded a technology addiction program,{" "}
              <Link href="https://orpheuspledge.org/">Orpheus Pledge</Link>.
              <br />
              <br />
              Right now, I am thinking about:
              <br />
              <ul>
                <li>Social design of innovation hubs</li>
                <li>Media literacy to combat AI disinformation</li>
                <li>
                  Spirituality in ancient and modern Chinese history and
                  politics
                </li>
                <li>
                  Lack of vocational schools and massive teacher shortage in the
                  U.S.
                </li>
              </ul>
              <br />
              Day to day, I enjoy <RouterLink to="/words">
                writing
              </RouterLink>{" "}
              and making <RouterLink to="art">art</RouterLink>.
              <br />
              <br />
              You can reach me at {isMobile ? <br /> : null}mjfang533 at gmail
              dot com.
            </Text>
          </Styled.Content>
          <Styled.LinkBar>
            <Nav leftLabel="Words" leftRoute="/words" />
          </Styled.LinkBar>

          <Styled.Seed1
            src={seed1}
            height={isMobile ? 60 : 100}
            width="auto"
            alt="home_seed1"
          />

          <Link href="https://yaledailynews.com/blog/2018/11/05/sunflower-seeds/">
            <Styled.Seed2>
              <img
                className="default"
                src={seed2}
                height={isMobile ? 80 : 140}
                width="auto"
                alt="home_seed2"
              />
              <img
                className="hovered"
                src={seed2Hover}
                height={isMobile ? 80 : 140}
                width="auto"
                alt="home_seed2_hover"
              />
            </Styled.Seed2>
          </Link>
        </Styled.InnerContainer>
      </Styled.OuterContainer>
    </Styled.Container>
  );
};
