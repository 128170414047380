import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Text } from "../ui";

import nav_l from "../seeds/nav_l.png";
import nav_r from "../seeds/nav_r.png";

const Container = styled.div`
  position: relative;
  width: 160px;
  height: 80px;
`;

const LeftSeed = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
`;

const RightSeed = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(20deg);
`;

const LeftLabel = styled.div`
  position: absolute;
  left: 60px;
  bottom: 15px;
  transform: translateX(-50%);

  a {
    text-decoration: none;
  }
`;

const RightLabel = styled.div`
  position: absolute;
  left: 64%;
  top: 22px;

  a {
    text-decoration: none;
  }
`;

export const Nav = ({
  leftLabel = "MF",
  rightLabel = "Art",
  leftRoute = "/",
  rightRoute = "/art",
}) => {
  return (
    <Container>
      <LeftSeed src={nav_l} width={95} alt="left_seed" />
      <LeftLabel>
        <Link to={leftRoute}>
          <Text type="text-t2">{leftLabel}</Text>
        </Link>
      </LeftLabel>
      <RightSeed src={nav_r} width={75} alt="right_seed" />
      <RightLabel>
        <Link to={rightRoute}>
          <Text type="text-t2">{rightLabel}</Text>
        </Link>
      </RightLabel>
    </Container>
  );
};
