export const palette = {
  white: "#fff",
  black: "#000",
  "green-400": "#7FAC6E",
  "green-500": "#075024",
  beige: "#FFFEE7",
  "yellow-400": "#FFFCB8",
  "yellow-500": "#dbd89e",
  "red-300": "#FED5D3",
  "red-400": "#EF5B5B",
  "red-500": "#EA4141",
  maroon: "#A04C4C",
};
