import styled, { css } from "styled-components";
import { GridBackground } from "../ui";
import { Color } from "../util";

export const Container = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.palette.beige};
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;

  ${GridBackground()}
`;

export const Content = styled.div`
  margin: 100px auto 30px auto;
  display: grid;
  grid-template-columns: auto 411px;
  column-gap: 16px;
  height: fit-content;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin: 30px 12px 30px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Header = styled.div`
  margin-top: 8px;

  > :not(:first-child) {
    margin-top: 8px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 0px;
    margin-bottom: 20px;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Writings = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  border: 2px solid ${({ theme }) => theme.palette["green-400"]};
  border-radius: 20px;
  padding: 16px;

  > :not(:first-child) {
    margin-top: 24px;
  }

  li {
    margin-bottom: 20px;
  }
`;

export const WritingSection = styled.div`
  > :first-child {
    margin-bottom: 20px;
  }
`;

export const NavContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const Seed1 = styled.div`
  z-index: 1;

  div {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-60%, -40%) rotate(1deg);
    transition: transform 0.2s;
    width: 569px;
    height: 569px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.3s;
  }
  .hovered {
    opacity: 0;
  }
  .default {
    opacity: 1;
  }

  ${({ hovered }) =>
    hovered &&
    `
    div {
      transform: translate(-60%, -40%) rotate(6deg);
    }
    .hovered {
      opacity: 1;
    }
    .default {
      opacity: 0;
    }
  `}
`;

export const Hitboxes = styled.div`
  position: absolute;
  top: 50%;
  left: 0%;
  z-index: 10;

  transition: transform 0.2s;
  ${({ hovered }) => hovered && "transform: rotate(6deg);"}
`;

const HitboxCSS = css`
  position: absolute;
  /* background-color: ${({ theme }) =>
    Color.makeHexTranslucent(theme.palette.maroon, 0.4)}; */
  top: 0;
  left: 0;

  &:hover {
    cursor: pointer;
  }
`;

export const Hitbox1 = styled.a`
  ${HitboxCSS}
  height: 280px;
  width: 130px;
  transform: translate(-215%, -5%) rotate(-20deg);
  border-radius: 250px 140px 100% 300%;
`;

export const Hitbox2 = styled.a`
  ${HitboxCSS}
  height: 280px;
  width: 130px;
  transform: translate(-100%, -60%) rotate(-5deg);
  border-radius: 200px 250px 200% 100%;
`;

export const Hitbox3 = styled.a`
  ${HitboxCSS}
  height: 300px;
  width: 130px;
  transform: translate(15%, -28%) rotate(-130deg);
  border-radius: 100% 300% 100% 100%;
`;

export const Seed2 = styled.img`
  position: absolute;
  bottom: 0px;
  right: 0px;

  transform: translate(30%, 85%) rotate(-95deg);
`;
