import React from "react";
import styled, { ThemeProvider } from "styled-components";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { base, GlobalStyle } from "./ui";
import { Home, Words, Art } from "./pages";

const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  min-height: 100vh;
`;

function App() {
  return (
    <Router>
      <ThemeProvider theme={base}>
        <GlobalStyle />
        <Container>
          <Routes>
            <Route path="/words" element={<Words />} />
            <Route path="/art" element={<Art />} />
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </Container>
      </ThemeProvider>
    </Router>
  );
}

export default App;
