import styled from "styled-components";
import { GridBackground } from "../ui";

export const Container = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
  justify-content: center;

  ${GridBackground()}
`;

export const OuterContainer = styled.div`
  padding: 8px;
  margin: 20px auto;
  border: 2px solid ${({ theme }) => theme.palette["green-400"]};
  height: fit-content;
  background-color: ${({ theme }) => theme.palette.white};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin: 8px;
  }
`;

export const InnerContainer = styled.div`
  border: 2px solid ${({ theme }) => theme.palette["green-400"]};
  position: relative;
`;

export const Content = styled.div`
  margin: 118px 80px 30px 80px;

  max-width: 464px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin: 60px 12px 30px 12px;
  }
`;

export const LinkBar = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;

export const Seed1 = styled.img`
  position: absolute;
  bottom: 8px;
  left: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    bottom: 0;
    left: 0;
  }
`;

export const Seed2 = styled.div`
  cursor: pointer;

  img {
    transform: rotate(-90deg);
    transition: opacity 0.3s, transform 0.2s;
    position: absolute;
    top: 8px;
    right: -8px;
  }
  .hovered {
    opacity: 0;
  }
  .default {
    opacity: 1;
  }

  &:hover {
    img {
      transform: rotate(-95deg);
    }
    .hovered {
      opacity: 1;
    }
    .default {
      opacity: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    img {
      top: -4px;
      right: -4px;
      transform: rotate(-135deg);
    }

    &:hover {
      img {
        transform: rotate(-140deg);
      }
    }
  }
`;
