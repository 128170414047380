import React, { useEffect, useState } from "react";
import { Link, Text } from "../ui";

import * as Styled from "./words.styles";
import seed1 from "../seeds/writing_1.png";
import seed1Hover from "../seeds/writing_1_hover.png";
import seed2 from "../seeds/writing_2.png";
import { Nav } from "../components";
import { useDimensions } from "../hooks/useDimensions";

export const Words = () => {
  const { isMobile } = useDimensions();
  const [hovered, setHovered] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Header>
          <Text type="display-h2" color="green-500">
            Michelle Fang
          </Text>
          <Text type="display-h2" color="green-500">
            Selected Writings
          </Text>
        </Styled.Header>
        <Styled.Writings>
          <Styled.WritingSection>
            <Text type="display-h3">Tech Policy</Text>
            <Text type="text-t2">
              <ul>
                <li>
                  Oversight of A.I.: Rules for Artificial Intelligence,{" "}
                  <Link href="https://www.judiciary.senate.gov/committee-activity/hearings/oversight-of-ai-rules-for-artificial-intelligence">
                    Senate Judiciary Subcommittee on Privacy, Technology, and
                    the Law.
                  </Link>{" "}
                  I organized and strategized the first hearing where Sam
                  Altman, CEO of Open AI, testified in Congress. Drafted the
                  hearing questions, memo, briefed the Senator, strategized with
                  the senior policy team - this was very, very fun. Covered in
                  the{" "}
                  <Link href="https://www.bloomberg.com/news/articles/2023-05-16/a-us-senator-just-used-chatgpt-for-opening-remarks-at-a-hearing">
                    Bloomberg
                  </Link>
                  ,{" "}
                  <Link href="https://www.nytimes.com/2023/05/16/technology/openai-altman-artificial-intelligence-regulation.html">
                    NYT
                  </Link>
                  ,{" "}
                  <Link href="https://www.wsj.com/articles/chatgpts-sam-altman-faces-senate-panel-examining-artificial-intelligence-4bb6942a">
                    WSJ
                  </Link>
                  , etc.
                </li>
                <li>
                  Online Choice Architecture and Behavioral Economics — The Role
                  for Antitrust Law,{" "}
                  <Link href="https://som.yale.edu/centers/thurman-arnold-project-at-yale">
                    Thurman Arnold Project at Yale
                  </Link>
                  . I co-authored this paper with a Yale Law School Ph.D.
                  student in spring of 2023 and presented to top commissioners
                  at the Federal Trade Commission (FTC) and Department of
                  Justice (DOJ).
                </li>
                <li>
                  Cyber needs trust, let&apos;s look at Estonia,{" "}
                  <Link href="https://law.yale.edu/isp/initiatives/wikimedia-initiative-intermediaries-and-information/student-blog/cyber-needs-trust-lets-look-estonia">
                    Yale Law School Information Society Project
                  </Link>
                </li>
                <li>
                  Brave: Privacy-Aware Business Models,{" "}
                  <Link href="https://tobin.yale.edu/digital-economy-project/competition-and-new-digital-economy/privacy-aware-business-models">
                    Yale Tobin Center for Economic Policy
                  </Link>
                </li>
                <li>
                  Digital Transformation in Estonia: Lessons for Building a
                  Federal Digital Identity System in the United States,{" "}
                  <Link href="https://drive.google.com/file/d/1SH0Gdd_LRLvpoY56Rgaftu9EKUfwhpzr/view?usp=sharing">
                    Yale Senior Thesis Essays
                  </Link>
                </li>
              </ul>
            </Text>
          </Styled.WritingSection>
          <Styled.WritingSection>
            <Text type="display-h3">Essays</Text>
            <Text type="text-t2">
              <ul>
                <li>
                  Sunflower Seeds,{" "}
                  <Link href="https://yaledailynews.com/blog/2018/11/05/sunflower-seeds/">
                    Yale Daily News Magazine
                  </Link>
                </li>
                <li>
                  A thief of time,{" "}
                  <Link href="https://cauliflowerandcereal.substack.com/p/a-thief-of-time">
                    Cauliflower & Cereal
                  </Link>
                </li>
                <li>
                  God, found and lost?,{" "}
                  <Link href="https://yaledailynews.com/blog/2018/11/28/fang-god-found-and-lost/">
                    Yale Daily News Opinion
                  </Link>
                </li>
              </ul>
            </Text>
          </Styled.WritingSection>
        </Styled.Writings>

        {!isMobile && (
          <>
            <Styled.Seed1 hovered={hovered}>
              <div>
                <img
                  className="default"
                  src={seed1}
                  height={569}
                  width="auto"
                  alt="words_seed1"
                />
                <img
                  className="hovered"
                  src={seed1Hover}
                  height={569}
                  width="auto"
                  alt="words_seed1_hover"
                />
              </div>
            </Styled.Seed1>
            <Styled.Hitboxes hovered={hovered}>
              <Styled.Hitbox1
                href="https://yaledailynews.com/blog/2018/11/05/sunflower-seeds/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              />
              <Styled.Hitbox2
                href="https://yaledailynews.com/blog/2018/11/05/sunflower-seeds/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              />
              <Styled.Hitbox3
                href="https://yaledailynews.com/blog/2018/11/05/sunflower-seeds/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              />
            </Styled.Hitboxes>
            <Styled.Seed2
              src={seed2}
              height={300}
              width="auto"
              alt="words_seed2"
            />
          </>
        )}
      </Styled.Content>
      <Styled.NavContainer>
        <Nav />
      </Styled.NavContainer>
    </Styled.Container>
  );
};
