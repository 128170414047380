import React, { useState, useEffect } from "react";

import * as Styled from "./art.styles";
import { Text, Link } from "../ui";

import berkeley from "../art/berkeley.png";
import brynna from "../art/brynna.png";
import grandma from "../art/grandma.png";
import grandpa from "../art/grandpa.png";
import me from "../art/me.png";
import mom from "../art/mom.png";
import priya from "../art/priya.png";
import sanfrancisco from "../art/sanfrancisco.png";

import sticker1 from "../misc-assets/sticker1.png";
import sticker2 from "../misc-assets/sticker2.png";

import seed from "../seeds/art.png";
import { Nav } from "../components";
import { useDimensions } from "../hooks/useDimensions";

const art = {
  grandma,
  priya,
  me,
  mom,
  brynna,
  grandpa,
  sanfrancisco,
  berkeley,
};

export const Art = () => {
  const { width, isMobile } = useDimensions();
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.TextContent>
          <Styled.Header>
            <Styled.Sticker>
              <Styled.Sticker1
                src={sticker1}
                height={56}
                width="auto"
                alt="sticker1"
              />
              <Styled.MichelleHeader type="display-h2-mono" color="red-400">
                michelle&rsquo;s
              </Styled.MichelleHeader>
            </Styled.Sticker>
            <Styled.Sticker>
              <Styled.Sticker2
                src={sticker2}
                height={54}
                width="auto"
                alt="sticker2"
              />
              <Styled.ArtHeader type="display-h2-mono" color="red-400">
                works
              </Styled.ArtHeader>
            </Styled.Sticker>
          </Styled.Header>
          <Styled.Philosophy>
            <Text type="text-t2-mono" color="red-400">
              I like painting people, their colors, glances, wrinkles, and all.
              But I try to avoid &lsquo;over ascribing&rsquo; or retroactively
              giving stories to my work. Most of our decisions and actions
              require an explanation to fit a narrative, so I hope art is a
              liberating exception where my hands and mind can just do what they
              want.
              <br />
              <br />
              Currently, I&apos;m exploring print-making, gouache, and oil
              pastels.
              <br />
              <br />
              <i>These are all oil paintings, minus one graphite sketch.</i>
            </Text>
            <Text type="display-h4-mono" color="red-400">
              <ol>
                <li>&lsquo;Apo&rsquo;</li>
                <li>Priya</li>
                <li>Self portrait</li>
                <li>Ma</li>
                <li>Brynna</li>
                <li>&lsquo;Agong&rsquo;</li>
                <li>Bay Streets</li>
                <li>Berkeley</li>
              </ol>
            </Text>
            <Link href="https://www.instagram.com/michelle.musings">
              <Text type="text-t2-mono" color="red-400">
                Click here for instagram
              </Text>
            </Link>
          </Styled.Philosophy>
          {!isMobile && (
            <Styled.NavContainer>
              <Nav rightLabel="Words" rightRoute="/words" />
            </Styled.NavContainer>
          )}
        </Styled.TextContent>
        <Styled.Art>
          <Styled.FullRow>
            <img
              onClick={() => setSelected("grandma")}
              src={grandma}
              width="100%"
              alt="grandma"
            />
          </Styled.FullRow>
          <Styled.SplitRow>
            <img
              onClick={() => setSelected("priya")}
              src={priya}
              width="100%"
              alt="priya"
            />
            <img
              onClick={() => setSelected("me")}
              src={me}
              width="100%"
              alt="me"
            />
          </Styled.SplitRow>
          <Styled.FullRow>
            <img
              onClick={() => setSelected("mom")}
              src={mom}
              width="100%"
              alt="mom"
            />
          </Styled.FullRow>
          <Styled.SplitRow>
            <img
              onClick={() => setSelected("brynna")}
              src={brynna}
              width="100%"
              height={isMobile ? (1.27 * (width - 2 * 12 - 14)) / 2 : 302}
              alt="brynna"
            />
            <img
              onClick={() => setSelected("grandpa")}
              src={grandpa}
              width="100%"
              alt="grandpa"
            />
          </Styled.SplitRow>
          <Styled.FullRow>
            <img
              onClick={() => setSelected("sanfrancisco")}
              src={sanfrancisco}
              width="100%"
              alt="sanfrancisco"
            />
          </Styled.FullRow>
          <Styled.FullRow>
            <img
              onClick={() => setSelected("berkeley")}
              src={berkeley}
              width="100%"
              alt="berkeley"
            />
          </Styled.FullRow>
        </Styled.Art>
        {isMobile && <Nav rightLabel="Words" rightRoute="/words" />}
        <Styled.Seed src={seed} height={700} width="auto" alt="art_seed" />
      </Styled.Content>
      {!isMobile && selected && (
        <SelectedArt selected={selected} onClose={() => setSelected(null)} />
      )}
    </Styled.Container>
  );
};

const SelectedArt = ({ selected, onClose }) => {
  // disable scrolling
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  return (
    <Styled.Overlay onClick={onClose}>
      <Styled.SelectedImage
        onClick={(e) => e.stopPropagation()}
        src={art[selected]}
        alt={selected}
      />
    </Styled.Overlay>
  );
};
