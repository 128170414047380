export const Font = {
  Serif: "Lora, serif",
  SansSerif: "Raleway, sans-serif",
  Monospace: "Ubuntu Mono, monospace",
};

export const textStyles = {
  desktop: {
    "display-h1": `700 40px ${Font.Serif}`,
    "display-h2": `700 30px ${Font.Serif}`,
    "display-h2-mono": `700 30px ${Font.Monospace}`,
    "display-h3": `700 20px ${Font.Serif}`,
    "display-h4-mono": `700 14px ${Font.Monospace}`,
    "text-t1": `400 16px ${Font.Serif}`,
    "text-t2": `400 14px ${Font.Serif}`,
    "text-t2-mono": `400 14px ${Font.Monospace}`,
  },
  mobile: {
    "display-h1": `700 24px ${Font.Serif}`,
    "display-h2": `700 24px ${Font.Serif}`,
    "display-h2-mono": `700 30px ${Font.Monospace}`,
    "display-h3": `700 20px ${Font.Serif}`,
    "display-h4-mono": `700 14px ${Font.Monospace}`,
    "text-t1": `400 16px ${Font.Serif}`,
    "text-t2": `400 14px ${Font.Serif}`,
    "text-t2-mono": `400 14px ${Font.Monospace}`,
  },
};
